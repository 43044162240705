.input-group {
  .select-wrapper {
    flex: 1 1 auto;
  }
}
// .form-switch{
//   border: 1px solid red !important;
//   width: 100px !important;
//   .form-check-input{
//     //width: 3rem !important;
//     //height:1.1rem !important;
//     &::after,
//     &::before{
//       width: 1.5rem !important;
//       height: 1.5rem !important;
//     }
//   }
// }
.form-outline{
  .select-arrow{
    right:12px !important;
  }
}
.tic-datepicker {
  .datepicker-toggle-button{
    padding: 0;
    right:12px !important;
  }
}
.form-outline{
  .invalid-feedback, .valid-feedback {
    margin-top: 0 !important;
  }
}
.form-outline .invalid-feedback,
.form-outline .valid-feedback,
.form-outline .select-arrow,
.tic-datepicker .datepicker-toggle-button {
  top: 50% !important;
  transform: translateY(-50%);
}
.datepicker-modal-container{
  .datepicker-header{
    background-color: $kleidi-green;
  }
  .datepicker-footer{
    .datepicker-footer-btn{
      color: $kleidi-green;
    }
  }
}

.file-upload-wrapper .form_label{
  padding-left: 10px;
}

.form-check-input[type="checkbox"]:checked {
  background-color: $kleidi-green;
  border-color: $kleidi-green;
}
.form-outline .form-control:focus ~ .form-notch .form-notch-leading {
  border-top: .125rem solid $kleidi-green;
  border-bottom: .125rem solid $kleidi-green;
  border-left: .125rem solid $kleidi-green;
}
.form-outline .form-control:focus ~ .form-notch .form-notch-middle {
  border-bottom: .125rem solid;
  border-bottom-color: currentcolor;
  border-color: $kleidi-green;
  font-size: 1rem;
}
.form-outline .form-control:focus ~ .form-notch .form-notch-trailing {
  border-top: .125rem solid $kleidi-green;
  border-bottom: .125rem solid $kleidi-green;
  border-right: .125rem solid $kleidi-green;
}
.tic-datepicker .form-outline .form-control:focus ~ .form-label,
.form-label,
.form_label {
  padding-top: 0;
  font-size: 1rem !important;
}

a {
  color: $kleidi-green;
  &:hover{
    color: darken($kleidi-green, 15%);
  }
}
.form-control{
  //font-size: inherit !important;
}
// .form-notch{
//   font-size: .8rem !important;
// }
.invocing-fields{
  .form-check{
    position: relative;
  }
  .valid-feedback, .invalid-feedback{
    position: absolute;
    top:100%;
    right:1rem;
    .fa-times{
      display:none;
    }
  }
}

#dashboardFilter .was-validated .form-control:valid,
#dashboardFilter .was-validated .form-control:invalid {
  margin-bottom: 0rem !important;
}

form[name='first_login'] .form-outline .invalid-feedback.d-block{
  margin-top: 9.25rem;
  top: 0px;
  right: 15px;
}

.valid-feedback, .invalid-feedback{
  position: absolute;
  top:18px;
  right:15px;
}
.stepper-active .stepper-head-icon {
  background-color: $kleidi-orange;
}
.stepper-invalid .stepper-head-icon {
  background-color: $kleidi-green;
}
.main-container-login{
  height: 100%;
  .container-lg{
    position: static;
  }
  .login-form-container{
    width:70%;
    position: absolute;
    top: 50%;
    left:50%;
    transform:translate(-50%, -50%);
  }
}

.form-block{
  padding-top: 1rem;
  padding-bottom: 1rem;
  margin-bottom: 2rem;
}
.download-block{
  .form-label{
    padding-top: 1rem;
  }
}
.subcontractors-create-page, .subcontractors-adminfiles{
  .block{
    margin-top: 1rem;
  }
}
.invocing-form-page{
  .btn{
    margin-top: 2rem;
  }
}

.subcontractors-home,
.subcontractors-create-page,
.subcontractors-adminfiles {
  .welcome-message,
  .subcontractors-message{
    position: relative;
    padding: 1.5rem;
    &:before{
      content:url('../../../public/images/angle-gauche.svg');
      position: absolute;
      top:0;
      left:0;
      width: 80px;
      height: 80px;
    }
    &:after{
      content:url('../../../public/images/angle-droit.svg');
      position: absolute;
      bottom:0;
      right:0;
      width: 80px;
      height: 80px;
    }
  }
  .sub-form .block{
    padding: 1rem;
    margin-top: 2rem;
  }
}

.invocing-form-page{
  h3{
    margin-bottom: 1.5rem;
  }
  .header-forms{
    padding: 1rem;
  }
  .invocing-fields{
    padding-top: 1rem;
    padding-bottom: 3rem;
  }
  .invoce-progress, .invoce-amount, .invoce-amount-previous{
    margin-top: 2rem;
  }
  .download-block, .invocing-form{
    padding: 1rem;
  }
  .invocing-inputs-disabled:last-child{
    label{
      font-weight: bold;
    }
  }
  .invocing-inputs{
    padding-top: 1.5rem;
    padding-bottom: 1.5rem;
  }
}

.assigment-table .form-check{
  display: flex;
  .form-check-label{
    margin-right: 2rem;
  }
}

.subcontractors-adminfiles .download-block-right .input-radio{
  display:flex;
  .form-check{
    margin-right: 1rem;
  }
}

.subcontractors-adminfiles{
  .invocing-head-inputs{
    padding-top: 25px;
  }
  .invocing-head-fields{
    padding-top: 4rem;
    padding-bottom: 4rem;
  }
  .admin-listes{
    .form-label{
      color:#111111;
      font-weight: 600;
    }
  }
  .flex-forms{
    padding: 1rem;
  }
}

.project-forms{
  .select-customers, .select-business, h3, .batch-type, .batch-number{
    margin-bottom: 1.5rem;
  }
}

/** Imput Switch **/
.form-check-input {
  clear: left;
}

//.form-switch
.form-switch-md {
  margin-bottom: 1rem; /* JUST FOR STYLING PURPOSE */
}

.form-switch-md .form-check-input {
  height: 1.5rem;
  width: calc(2rem + 0.75rem);
  border-radius: 3rem;
}

.form-switch-md .form-check-input::after{
  margin-top: 0px;
  width: 1.50rem !important;
  height: 1.50rem !important;
}

/** Fin Imput Switch **/


form[name="project_edit"] .vich-image a img {
  width: 50%;
}

.assigment-table .edit-assigment .dropdown-menu.show,
.assigment-table .add-assigment .dropdown-menu.show{
  //border: 1px solid red;
  padding: 1rem;
  width: 70%;
  transform:translate3d(50%) !important;
}

.modal-content{
  padding: 1rem;
}

.use-conditions .link{
  padding-left: 1.5rem;
}
.dataTables_filter {
  .form-outline{
    margin-bottom: 1rem;
  }
}
// #projects{
//   .form-notch-middle{
//     font-size: 1rem !important;
//   }
// }
@media screen and (min-width: 768px){
  .invocing-form-page .invocing-fields{
    padding-bottom: 0;
    margin-bottom: 1rem;
  }
  .header-forms-container{
    margin-bottom: 4rem;
  }
  .subcontractors-adminfiles{
    .invocing-head-inputs{
      padding-top: 0;
    }
  }
}
