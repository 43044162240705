tr{
  vertical-align: middle;
}
th{
  padding: .5rem;
}
td.dtr-control{
  padding: .5rem;
}
td.dtr-control:first-child(){
  padding-left: 1.5rem;
}

.sorting{
  &:before, &:after{
    margin-bottom: 12px;
  }
}
.table > :not(caption){
  padding: 1rem 1rem;
}
.datatable thead {
  background-color: #212121!important;
  color: #fff;
}
.datatable td, .datatable th{
  text-align: right !important;
}
ul.dtr-details{
  width:100%;
}
.stepper{
  position: static;
}
.invoice{
  .fa-hourglass{
    color: red;
  }
 .disabled .fa-check{
    color:lightgrey;
  }
  .fa-file-invoice-dollar, .fa-check{
    color:green;
  }
  svg, i{
    font-size: 1rem;
  }
}
.dropdown.add-line-form,
.dropdown.add-assigment,
.dropdown.edit-assigment{
  // position: static;
}
.add-in-table, .dropdown-toggle{
  display: flex;
  justify-content:flex-end;
}
.dropdown-toggle:after{
  display: none;
}
.add-in-table, .edit-in-table{
  svg, i{
    margin-left: auto;
    font-size: 1.7rem;
    color:$kleidi-orange;
  }
}
.selling-info{
  .selling-table{
    margin-top: 4rem;
    margin-bottom: 4rem;
  }
}
.spendings-table{
  thead th:nth-child(5){
    width: 30%;
  }
}
.subcontractors-list{
  .input-group{
    width: 30%;
  }
  .input-group .btn{
    height: 35px;
  }
}
#subcontractors-datatable{
  tr[data-mdb-index="1"]{

  }
  th, td{
    white-space:normal;
    width: 100px !important;
  }
}
.assigment-page .total{
  padding: 1rem;
  font-size: 1.5rem;
}


.fixedHeaderContainer {
  overflow-y: auto;
  max-height: 500px;
}

.fixedHeaderContainer th{
  position: -webkit-sticky !important;
  position: -moz-sticky !important;
  position: -ms-sticky !important;
  position: -o-sticky !important;
  position: sticky !important;
  top: 0;
  z-index: 1;
}

.fixedHeaderContainer tfoot tr{
  position: -webkit-sticky !important;
  position: -moz-sticky !important;
  position: -ms-sticky !important;
  position: -o-sticky !important;
  position: sticky !important;
  z-index: 1;
  bottom: 0;
}

.fixedHeaderContainer thead {
  inset-block-start: 0; /* "top" */
}
.fixedHeaderContainer tfoot {
  inset-block-end: 0; /* "bottom" */
}
