// font awesome free
/*
@import '../../vendor/components/font-awesome/scss/fontawesome';
@import '../../vendor/components/font-awesome/scss/solid';
@import '../../vendor/components/font-awesome/scss/regular';
@import '../../vendor/components/font-awesome/scss/brands';
*/
//font awesome pro
@import '../../node_modules/fontawesome-pro/scss/fontawesome.scss';
@import '../../node_modules/fontawesome-pro/scss/solid.scss';
@import '../../node_modules/fontawesome-pro/scss/regular.scss';
@import '../../node_modules/fontawesome-pro/scss/brands.scss';

// datatables and plugins
@import '../../node_modules/datatables.net-bs5/css/dataTables.bootstrap5.css';
@import '../../node_modules/datatables.net-buttons-bs5/css/buttons.bootstrap5.css';
@import '../../node_modules/datatables.net-responsive-bs5/css/responsive.bootstrap5.css';

table.fixedHeader-floating { margin-top: 0 !important; }

@font-face {
  font-family: "kiona-regular";
  src: url("../fonts/kiona/kiona-regular-webfont.woff");
}

h2{
 font-family: "kiona-regular";
}

// Surcharge de Material Design Bootstrap 5 avec variables personnalisées
@import "scss/_mdb_custom";


//====== Fichier btn-link.
@import 'scss/_btn-link';
//====== Fichier card.
@import 'scss/_card';
//====== Fichier footer.
@import 'scss/_footer';
//====== Fichier forms.
@import 'scss/_forms';
//====== Fichier header.
@import 'scss/_header';
//====== Fichier main.
@import 'scss/_main';
//====== Fichier modal.
@import 'scss/_modal';
//====== Fichier nav.
@import 'scss/_nav';
//====== Fichier tables.
@import 'scss/_tables';
