.main-container-login {
  .logo {
    padding-top: 2rem;
    padding-bottom: 1rem;
  }

  .login-title {
    font-size: 1.5rem;
  }
}

.konia {
  font-family: "kiona-regular", serif;
}
.header-layout {
  border-bottom-style: solid;
  border-bottom-color: #167d93;
  border-bottom-width: 2px;
}

.brand {
  padding-top: .5rem;
  padding-bottom: .5rem;
  padding-right: .5rem;
  height: 100%;

  .header-main {
    display: flex;
    justify-content: space-between;
    align-items: center;

    img {
      width: 80px;
    }

    h1 {
      font-size: 1rem;
    }
  }

  .main-title {
    height: 100%;
  }

  .icon {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 100%;
    padding: 1rem;
    width: 60px;
    height: 60px;
    box-shadow: 0px 0px 10px #aaa;
    cursor: pointer;

    svg {
      font-size: 1.5rem;
    }

    b {
      display: block;
      font-size: .5rem;
    }
  }
}

.container-lg {
  position: relative;
}

.main-container {

  height: 100%;
  // overflow-x:hidden;
  // overflow-y:scroll;
}

.menu-board {
  background-color: $kleidi-green;
  /*top: 163px;*/
  /*position: absolute;
  z-index: 1000;
  right: -100vw;
  width: 100vw;
  height: calc(100% - 163px);
  padding-top: 1rem;
  */
  right: 0;
  left: unset;
  transform: translateX(100%);

  li a {
    color: white;
    font-size: 1.1rem;
  }

  li .sidenav-link {
    &:active,
    &:hover,
    &:focus {
      color: white;
      font-weight: bold;
    }

    &.active {
      color: white;
      font-weight: bold;
    }
  }
}

.slide-right {
  transition-property: transform;
  transition-duration: 1s;
  transition-timing-function: ease;
  transform: translateX(0);
}

.slide-left {
  transition-property: transform;
  transition-duration: 1s;
  transition-timing-function: ease;
  transform: translateX(-100vw);
}

@media screen and (min-width: 768px) {
  .brand .header-main {
    display: flex;
    justify-content: flex-start;
  }
  .brand .header-main img {
    width: 150px;
  }
  .brand .header-main h1 {
    margin: 0 auto;
    font-size: calc(1.375rem + 1.5vw);
  }
  .logo img {
    width: 80%;
  }
}

@media screen and (min-width: 1024px) {
  .brand .icon {
    height: 100%;
    // padding: 1.5rem;
    width: 90px;
    height: 90px;

    svg {
      font-size: 2rem;
    }

    b {
      font-size: .8rem;
    }
  }
}
