#icon-user { font-size: 1rem; color: #2b3034; }

.btn-archive, .btn-delete{
  background-color: transparent;
  border: 0px;
  color: #167d93;
  i.fa-check{
    color: #167d93 !important;
  }
}

.btn-grp-actions{
  display: flex;
  justify-content: flex-end;
/*
  #btnShow, #btnAccountValidation, #btnList{
    margin-right: 4px;
  }
*/
  a.button, button { margin:0; padding: 0; }
  a.button:not(:last-child), button:not(:last-child) {
    margin-right: 4px;
  }
}

#tableLotFinancialSchedule .actions .btn-primary.btn-archive,
#tableLotFinancialSchedule i.fa-check,
#accordion-assigned-company .actions .btn-primary.btn-archive,
#accordion-assigned-company i.fa-check {
  color: #fff !important;
}

.btn-primary{
  background-color: $kleidi-orange;
  &:hover{
    background-color: lighten($kleidi-orange, 15%);
  }
}
.btn-secondary{
  background-color: white;
  border: 1px solid $kleidi-orange;
  color: $kleidi-orange;
  &:hover{
    background-color: lighten($kleidi-orange, 15%);
    border-color:lighten($kleidi-orange, 15%);
    color: white;
  }
}
.btn-secondary.active{
  background-color: red;
}
.btn-prev-next{
  padding-left: 1rem;
  padding-right: 1rem;
  background-color: white;
  border: 2px solid $kleidi-green;
  // color: $kleidi-orange;
  //font-size: 1rem;
  height: 40px;
  color: $kleidi-green;
  &:hover{
    background-color: $kleidi-green;
    color: white;
  }
}

.btn-primary.focus, .btn-primary:focus{
  color: #fff;
  background-color: #147b93;
}
.icon-as-btn{
  position:relative;
  @include border-radius(50%);
  display:inline-block;
  width:35px;
  height:35px;
  border:2px solid $kleidi-orange;
  svg, i{
    position: absolute;
    top:50%;
    left:50%;
    transform: translate(-50%, -50%);
  }
}

.icon-as-btn.success{
  border: 2px solid green;
}
.icon-as-btn.danger{
  border: 2px solid red;
}

.link-as-tabs-container{
  margin-top: 2rem;
}
.link-as-tabs{
  font-size: .7rem;
  text-transform:uppercase;
  color: lighten(grey, 15%);
  &:hover{
    background-color:gba(0,0,0,.6);
  }
}
.link-as-tabs.active{
  color: $kleidi-orange;
  border-bottom: 1px solid $kleidi-orange;
  &:hover{
    background-color:gba(0,0,0,.6);
  }
}
.accordion .accordion-button{

  &:not(.collapsed){
    box-shadow:none;
    color: $kleidi-green;
  }
  &:after{
    color: $kleidi-green !important;
  }
  .company-name{
    flex-grow:1;
    font-weight: bold;
  }
  .company-budget{
    text-align: right;
  }
  .company-name, .company-budget{
    margin-right: 40px;
  }
}

@media screen and (min-width: 768px){
  .link-as-tabs{
    font-size: .9rem;
    padding: 17px 29px 16px;
    color: lighten(grey, 15%);
    &:hover{
      background-color:gba(0,0,0,.6);
    }
  }
}
