.modal-footer{
  a{
    display:flex;
    align-items: center;
  }
  span{
    //display: block;
    &:first-child{
      // height:35px;
      // margin-right: .5rem;
    }
  }
}
.modal{
  .fa-exclamation-triangle{
    color: $kleidi-green;
    font-size: 3rem;
  }
}
#subcontractorsList-modal{
  li{
    padding-bottom: 1rem;
  }
  .modal-header{
    margin-bottom: 2rem;
  }
  .modal-label{
    font-weight: bold;
  }
  .modal-table-title{
    margin-top: 3rem;
    font-style:italic;
  }
  .icon-as-btn svg{
    text-align: right;
  }
  // span{
  //   margin-right: .5rem;
  // }
}
#assignedCompany-modal{
  .modal-body a{
    display: block;
    padding: 1rem;
    text-align: right;
  }
}
