.subcontractors-home, .subcontractors-list {
  .nav-link {
    font-size: .8rem;
  }
  .nav-link.active{
    color: $kleidi-orange;
    border-color:$kleidi-orange;
  }
}
.tabs-title{
  text-transform: uppercase;
}
.nav-tabs {
  .nav-link{
    padding: 0 0 .5rem 0;
  }
  .badge{
    margin-left: .4rem;
    font-size: 0.78rem;
  }
}

// Mise en place du nouveau menu
#main-sidenav {
  background-color: $kleidi-green;
  color: #ffffff;
  li a{
    font-size: 1.1rem;
  }
  .sidenav-link.active{
    color: black;
  }
  li a:focus, li a:hover{
    color: #126476;
  }
}

.subcontractors-home #home-tabs{
  display: flex;
  justify-content: space-around;
}


@media screen and (min-width: 768px){
  .nav-tabs .nav-link{
    padding: 17px 29px 16px;
  }
}
