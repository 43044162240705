
.login-main, .company-message{
  .card{
    position: absolute;
    top:50%;
    transform: translateY(-50%);
  }
}
.card-text{
  padding-top: 2rem;
}
.main-container-company-message {
  .container-lg{
    position: static;
  }
}
@media screen and (min-width: 768px){
  .login-main, .company-message{
    width: 70vw;
    .card{
      position: absolute;
      top:50%;
      left: 50%;
      transform: translate(-50%, -50%);
    }
  }
}
