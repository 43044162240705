@mixin border-radius($radius: 1%) {
  -webkit-border-radius: $radius;
  -moz-border-radius: $radius;
  border-radius: $radius;
}

html {
  width: 100%;
  min-height: 100%;
}

body {
  font-family: 'Raleway', sans-serif;
  color: #2b3034;
  // height: 100%;
  overflow-x: hidden;
  width: 100%;
}

p {
  margin-bottom: 0 !important;
}

a {
  // cursor:pointer;
}

ul {
  padding: 0;
}

ul li {
  list-style-type: none;
}

h1, h2 {
  margin-bottom: 0 !important;
}

.full-vh {
  height: 100vh;
}

.frame {
  border: 1px solid red;
}

.margin-bot-1 {
  margin-bottom: 1rem;
}

.margin-bot-2 {
  margin-bottom: 2rem;
}

.margin-bot-5 {
  margin-bottom: 5rem;
}

.margin-top-1 {
  margin-top: 1rem;
}

.margin-top-2 {
  margin-top: 2rem;
}

.margin-top-5 {
  margin-top: 5rem;
}

.main-container {
  overflow-x: hidden;
}

.admin-content {
  margin-bottom: 25px;
}

.green {
  color: green;
}

.orange {
  color: orange;
}

.red {
  color: red;
}

.kleidi-green {
  color: $kleidi-green;
}

.bloc-border-bot {
  border-bottom: 1px solid grey;
  margin-bottom: 1rem;
}

.bd-right-blue {
  border-right: 1px solid $kleidi-green;
}

.text-align-R {
  text-align: right;
}

.invoce.progress {
  margin-bottom: 5rem;

  .progress-bar {
    background-color: $kleidi-orange;
  }
}

.hide {
  visibility: hidden;
}

.bold {
  font-weight: bold;
}

.v-align {
  display: flex;
  justify-content: space-between;
}

.second-title {
  border-bottom: 2px solid $kleidi-green;
  padding-bottom: 1rem;
  color: $kleidi-green;
}

.main-title h1 {
  font-size: calc(1.375rem + -1.5vw);
}

sup {
  top: 50%;
  transform: translateY(-50%);
}

//les margin en mobile
// .inprogress, .invocing-form-page, .second-title{
//   margin-top: 2rem;
// }
.filters-bar {
  font-size: .75rem;

  input[type="search"] {
    font-size: .75rem;
    min-height: 30px;
    height: 30px;

  }

  select, .input-group-text {
    min-height: 30px;
    height: 30px;
  }

  .form-outline .form-control {
    padding: 0 .75em;
    min-height: 30px;
    height: 30px;
  }

  /*	negative impact on switch
    .form-check-input{
      width: .9rem;
      height: .9rem;
      border: .1rem solid #757575;
    }
  */
}

.d-block {
  // display: block;
}

// Filtre sur les images du projet
img.filter-image {
  max-height: 120px;
  //width: 90px;
}

img.filter-image-list {
  height: 57px;
  width: 57px;
  object-fit: cover;
}

@media screen and (min-width: 768px) {
  .main-title h1 {
    font-size: calc(1.375rem + 1.5vw);
  }
  .popconfirm-modal, .popconfirm-popover {
    width: auto;
  }
  //les margin à partir de la tablette
  // .invoce-table, .inprogress, .invocing-form-page{
  //   margin-top: 5rem;
  // }
}

.rating {
  position: relative;
  width: 180px;
  background: transparent;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: .3em;
  padding: 5px;
  overflow: hidden;
  border-radius: 20px;
  box-shadow: 0 0 2px #b3acac;
  z-index: 1;
}

.rating__result {
  position: absolute;
  top: 0;
  left: 0;
  transform: translateY(-7px) translateX(-1px);
  z-index: -9;
  font: 3em Arial, Helvetica, sans-serif;
  color: #ebebebd9;
  pointer-events: none;
}

.rating__star {
  font-size: 1.3em;
  cursor: pointer;
  color: #dabd18b2;
  transition: filter linear .3s;
}

.rating__star:hover {
  filter: drop-shadow(1px 1px 4px gold);
}
