//====== Fichier variables custom.
@import 'variables';


@import "../../../node_modules/mdb5-pro/src/scss/mdb.pro.scss";

// MDB plugins
@import "../../../node_modules/mdb5-pro/src/plugins/file-upload/scss/_file-upload.scss";
@import "../../../node_modules/mdb5-pro/src/plugins/input-mask/scss/_inputmask.scss";
@import "../../../node_modules/mdb5-pro/src/plugins/wysiwyg/scss/_wysiwyg.scss";

/*
// MDB FREE
@import '../../../node_modules/mdb5-pro/src/scss/mdb.free.scss';

// MDB PRO
@import '../../../node_modules/mdb5-pro/src/scss/pro/variables';
@import '../../../node_modules/mdb5-pro/src/scss/pro/modal';
@import '../../../node_modules/mdb5-pro/src/scss/pro/perfect-scrollbar';
@import '../../../node_modules/mdb5-pro/src/scss/pro/sidenav';
@import '../../../node_modules/mdb5-pro/src/scss/pro/animate';
@import '../../../node_modules/mdb5-pro/src/scss/pro/lightbox';
@import '../../../node_modules/mdb5-pro/src/scss/pro/rating';
@import '../../../node_modules/mdb5-pro/src/scss/pro/timepicker';
@import '../../../node_modules/mdb5-pro/src/scss/pro/navbar';
@import '../../../node_modules/mdb5-pro/src/scss/pro/datepicker';
@import '../../../node_modules/mdb5-pro/src/scss/pro/popconfirm';
@import '../../../node_modules/mdb5-pro/src/scss/pro/datatable';
@import '../../../node_modules/mdb5-pro/src/scss/pro/stepper';
@import '../../../node_modules/mdb5-pro/src/scss/pro/sticky';
@import '../../../node_modules/mdb5-pro/src/scss/pro/select';
@import '../../../node_modules/mdb5-pro/src/scss/pro/loading';
@import '../../../node_modules/mdb5-pro/src/scss/pro/autocomplete';
@import '../../../node_modules/mdb5-pro/src/scss/pro/theme/theming';
@import '../../../node_modules/mdb5-pro/src/scss/pro/chips';
@import '../../../node_modules/mdb5-pro/src/scss/pro/multi-range';
@import '../../../node_modules/mdb5-pro/src/scss/pro/date-time-picker';
*/


/* undo datatables css for input-select */
div.dataTables_wrapper {
  div.dataTables_filter {
    input.select-input {
      margin-left: 0;
      width: 100%;
    }
    .form-outline {
      input.form-control { width: 100%; margin-left: 0; }
    }
  }
}
